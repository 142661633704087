





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OPartnerList: () => import('@/components/organisms/event/website/config/partners/o-partner-list.vue')},

  setup(){

    return {}
  }
})
